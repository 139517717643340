import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions, mapMutations } from 'vuex'
library.add(fas)
export default {
  name: 'vue_star_rating',
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      stars: this.star,
      star_desc: this.getRatingDesc(this.star),
      contentState: {
        rating: 0
      }
    }
  },
  props: {
    star: {
      type: Number
    },
    maxstars: {
      type: Number,
      default: 5
    },
    hasresults: {
      type: Boolean,
      default: true
    },
    hasdescription: {
      type: Boolean,
      default: true
    },
    ratingdescription: {
      type: Array,
      default: () => {
        return [
          {
            text: 'Poor',
            class: 'star-poor'
          },
          {
            text: 'Below Average',
            class: 'star-belowAverage'
          },
          {
            text: 'Average',
            class: 'star-average'
          },
          {
            text: 'Good',
            class: 'star-good'
          },
          {
            text: 'Excellent',
            class: 'star-excellent'
          }
        ]
      }
      // default: ["Poor", "Below Average", "Average", "Good", "Excellent"]
    },
    starsize: {
      type: String,
      default: '2x'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions([
      'UPDATE_USER_CONTENT_STATE'
    ]),
    ...mapMutations([
      'setIsLessonRated'
    ]),
    rate (star) {
      if (this.disabled) {
        return
      }
      if (star <= this.maxstars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star
        this.contentState.rating = this.stars
        this.UPDATE_USER_CONTENT_STATE({
          contentId: this.$route.params.contentId,
          payload: this.contentState
        })
        this.setIsLessonRated(true)
      }
    },
    hoverStar (star) {
      if (this.disabled) {
        return
      }
      if (star <= this.maxstars && star >= 0) {
        this.star_desc = this.ratingdescription[star - 1]
      }
    },
    mouseLeftStar () {
      if (this.disabled) {
        return
      }
      if (this.stars) {
        this.star_desc = this.ratingdescription[this.stars - 1]
        return this.star_desc
      } else {
        this.star_desc = ''
      }
    },
    getRatingDesc (star) {
      if (star) {
        this.star_desc = this.ratingdescription[star - 1]
      }
      return this.star_desc
    }
  }
}
